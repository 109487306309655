<template>
  <div class="topbar">
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <DarkMode
          defaultMode="light"
          :modes="['light', 'dark']"
        >
          <template v-slot="{ mode }">
            <div
              class="
                btn btn-icon btn-clean btn-dropdown btn-lg
                mr-1
                pulse pulse-primary
              "
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Code/Compiling.svg" />
              </span>
              <span class="pulse-ring"></span>
            </div>
          </template>
        </DarkMode>
      </template>
    </b-dropdown>
    <!--end: Notifications -->

    <KTQuickUser></KTQuickUser>
    <!--end::User-->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser";
import { DarkMode } from "@vue-a11y/dark-mode";

export default {
  name: "Topbar",
  components: {
    KTQuickUser,
    DarkMode,
  },
  mounted() {
    setTimeout(function () {
      document.body.style.transition = "background-color .3s";
    }, 1000);
  },
};
</script>
<style>
* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}
:root {
  --bg: red;
  --bg-secondary: #eef0f8;
  --color: #333;
}
html.dark-mode {
  --bg: #091a28;
  --bg-secondary: #413A71;
  --color: #d7dc09;
  --card-bg:#2F2F37;
  --textlabelkuning:#D7DC09;
  --cardtiketbg:linear-gradient(68.04deg, #18181C 9.08%, #413A71 93.75%);
  --texttiket:white;
  --formcolor:#636363;
  --bordercolorform: #6b6b6b;
  --txtlabeloption:#D7DC09;
  --txttabelcolor:#dedede;
  --tabelcolor:#2a2a2a;
}
html.light-mode {
  --card-bg:white;
  --textlabelkuning:#000000;
  --cardtiketbg:#F0FEFE;
  --texttiket:black;
  --bordercolorform: #E4E6EF;
  --txtlabeloption: #ababab;
  --txttabelcolor:#333;
  --tabelcolor:#fff;
}
.dark-mode img {
  filter: saturate(60%) brightness(80%);
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.8rem;
  background-color: var(--bg-secondary);
  color: var(--color);
}
a {
  color: #42b983;
}
.header {
  width: 100%;
  padding: 16px 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 2%;
}
.container > p {
  margin-bottom: 20px;
}
.container > p.image {
  text-align: center;
}
.btn-darkmode.vue-dark-mode {
  padding: 8px 12px;
}
.navbar {
  background-color: var(--bg-secondary);
  border-bottom: var(--border-table);
}
</style>

