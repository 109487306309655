<template>
  <div
    class="aside aside-left d-flex flex-column"
    id="kt_aside"
    ref="kt_aside"
    style="width: 120px"
  >
    <!--begin::Brand-->
    <KTBrand></KTBrand>
    <!--end::Brand-->
    <!--begin::Nav Wrapper-->
    <div
      class="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7"
      style="width: 120px"
    >
      <!--begin::Nav-->
      <ul class="nav flex-column">
        <!--begin::Item-->
        <router-link
          v-for="(menu, index) in menu"
          :key="`menu-${index}-${menu.title}`"
          :to="menu.target"
          v-slot="{ href, navigate }"
        >
          <li class="nav-item mb-14" v-b-tooltip.hover.right="menu.title">
            <a
              :href="href"
              @click="navigate"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
            >
              <span v-if="menu.routes.includes($route.name)">
                <inline-svg :src="menu.icons.active" width="112" />
              </span>
              <span v-else>
                <inline-svg :src="menu.icons.default" width="112" />
              </span>
            </a>
          </li>
        </router-link>
        <!--end::Item-->
      </ul>
      <!--end::Nav-->
    </div>
    <!--end::Nav Wrapper-->
    <!--begin::Footer-->
    <div
      class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
    >
      <div class="d-flex justify-content-center mb-1 mt-1">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a
            :href="href"
            class="menu-link"
            @click="$bvModal.show('bv-modal-example')"
            style="cursor:pointer;"
          >
            <!-- <div
                class="d-flex justify-content-center"
                style="margin-bottom: 10px"
            >
              <img
                  alt="Visit"
                  src="media/icon/svg/url.png"
                  class="logo-default max-h-20px"
              />
            </div> -->
            <!-- <div class="d-flex justify-content-center">
                <span class="menu-text" style="color: #d7dc09; bottom: 120px"
                >URL Aplikasi</span
                >
            </div> -->
          </a>
        </li>
      </div>
      <!--begin::Quick Panel-->
      <div class="d-flex justify-content-center mb-5 mt-5">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a
            :href="href"
            class="menu-link"
            @click.prevent="logOut"
            style="cursor:pointer;"
          >
            <div
              class="d-flex justify-content-center"
              style="margin-bottom: 10px"
            >
              <img
                alt="Visit"
                src="media/icon/svg/vector_signout.svg"
                class="logo-default max-h-20px"
              />
            </div>
            <div class="d-flex justify-content-center">
              <span class="menu-text" style="color: #d7dc09; bottom: 120px"
                >Sign Out</span
              >
            </div>
          </a>
        </li>
      </div>
      <!--end::Quick Panel-->
    </div>
    <!--end::Footer-->
    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>URL Aplikasi</template>
      <div class="d-block text-center">
        <div>
          <b-form-group>
            <b-form-radio
              stacked
              v-model="urlSelected"
              checked
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="https://zipato.sisnet.my.id/zipato-web/app2login"
              >Aplikasi Visit</b-form-radio
            >
            <b-form-radio
              stacked
              v-model="urlSelected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="https://zipato.sisnet.my.id/zipato-web/app2login"
              >Aplikasi Visitor</b-form-radio
            >
          </b-form-group>
        </div>
      </div>
      <b-button class="mt-3" variant="danger" block @click="GoToUrl"
        >Go To Link</b-button
      >
    </b-modal>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}

.menu {
  font: 14px/1.5 "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu a {
  display: block;
  padding: 0px;
  color: #fff;
  text-decoration: none;
}

.menu li {
  display: block;
  float: right;
  position: relative;

  color: #fff;
  min-width: 180px;
}

.menu li ul {
  position: absolute;
  left: 0;
  top: 61px;
  margin: 0;
  padding: 0;
}

.menu li ul li {
  background: #333;
  transition: background 0.2s;
}

.menu li ul li:hover {
  background: #444;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<script>
import KTBrand from "@/view/layout/brand/Brand";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import Swal from "sweetalert2";
import WebService from "../../../core/services/api";

export default {
  data() {
    return {
      listOne: false,
      urlSelected: "",
      menu: [],
      role_id: ""
    };
  },
  name: "Aside",
  components: {
    KTBrand
  },

  computed: {
    menus() {
      return [
        {
          title: "Dashboard",
          target: "/dashboard",
          routes: ["dashboard"],
          icons: {
            active: "media/icon/svg/dashboard_active2.svg",
            default: "media/icon/svg/dashboard_def2.svg"
          }
        },
        {
          title: "Visits",
          target: "/visit",
          routes: ["visit"],
          icons: {
            active: "media/icon/svg/visit_active2.svg",
            default: "media/icon/svg/visit_def2.svg"
          }
        },
        {
          title: "Site Data",
          target: "/sitedata",
          routes: ["sitedata"],
          icons: {
            active: "media/icon/svg/site_data_active2.svg",
            default: "media/icon/svg/site_data_def2.svg"
          }
        },
        {
          title: "Ticketing",
          target: "/ticketing",
          routes: ["ticketing"],
          icons: {
            active: "media/icon/svg/ticketing_active2.svg",
            default: "media/icon/svg/ticketing_def2.svg"
          }
        },
        {
          title: "Maintenance",
          target: "/maintenance",
          routes: ["maintenance"],
          icons: {
            active: "media/icon/svg/maintenance_active2.svg",
            default: "media/icon/svg/maintenance_def2.svg"
          }
        },
        {
          title: "Logistik",
          target: "/logistik",
          routes: ["logistik"],
          icons: {
            active: "media/icon/svg/logistik_active2.svg",
            default: "media/icon/svg/logistik_def2.svg"
          }
        },
        {
          title: "Sensor Status",
          target: "/sensorstat",
          routes: ["sensorstat"],
          icons: {
            active: "media/icon/svg/sensorstat_active2.svg",
            default: "media/icon/svg/sensorstat_def2.svg"
          }
        },
        {
          title: "CMS",
          target: "/cms",
          routes: ["cms"],
          icons: {
            active: "media/icon/svg/cms_active2.svg",
            default: "media/icon/svg/cms_def2.svg"
          }
        }
      ];
    }
  },
  methods: {
    GoToUrl() {
      window.open(this.urlSelected);
      this.$bvModal.hide("bv-modal-example");
    },
    logOut() {
      Swal.fire({
        title: "Anda yakin ingin keluar?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonColor: "#C60B09",
        confirmButtonText: "Keluar",
        cancelButtonText: "Batal",
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          localStorage.removeItem("profile");
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        }
      });
    },
    getMenu() {
      WebService.getUserLogged().then(response => {
        this.getRole(response.data.role_id);
      });
    },
    compare(prop) {
      return function(a, b) {
        console.log(a, b, prop); // outputs -> name
        return -1; // sort stuff
      };
    },
    getRole(idrole) {
      WebService.getRoleByID(idrole).then(
        res => {
          let menusarr = [];
          res.data.menus.forEach(items => {
            var menuicon;
            if (items.link.substring(1) == "dashboard") {
              menuicon = {
                active: "media/icon/svg/dashboard_active2.svg",
                default: "media/icon/svg/dashboard_def2.svg"
              };
            } else if (items.link.substring(1) == "visit") {
              menuicon = {
                active: "media/icon/svg/visit_active2.svg",
                default: "media/icon/svg/visit_def2.svg"
              };
            } else if (items.link.substring(1) == "sitedata") {
              menuicon = {
                active: "media/icon/svg/site_data_active2.svg",
                default: "media/icon/svg/site_data_def2.svg"
              };
            } else if (items.link.substring(1) == "ticketing") {
              menuicon = {
                active: "media/icon/svg/ticketing_active2.svg",
                default: "media/icon/svg/ticketing_def2.svg"
              };
            } else if (items.link.substring(1) == "maintenance") {
              menuicon = {
                active: "media/icon/svg/maintenance_active2.svg",
                default: "media/icon/svg/maintenance_def2.svg"
              };
            } else if (items.link.substring(1) == "logistik") {
              menuicon = {
                active: "media/icon/svg/logistik_active2.svg",
                default: "media/icon/svg/logistik_def2.svg"
              };
            } else if (items.link.substring(1) == "sensorstat") {
              menuicon = {
                active: "media/icon/svg/sensorstat_active2.svg",
                default: "media/icon/svg/sensorstat_def2.svg"
              };
            } else if (items.link.substring(1) == "cms") {
              menuicon = {
                active: "media/icon/svg/cms_active2.svg",
                default: "media/icon/svg/cms_def2.svg"
              };
            } else {
              menuicon = {
                active: "media/icon/svg/url_active2.svg",
                default: "media/icon/svg/url_def2.svg"
              };
            }
            menusarr.push({
              title: items.name,
              sort: items.sort,
              code: items.code,
              target: items.link,
              routes: [items.link.substring(1)],
              icons: menuicon
            });
            console.log(menusarr.sort((a, b) => Number(a.code) - Number(b.code)));
          });
          this.menu = menusarr.sort((a, b) => Number(a.code) - Number(b.code));
        },
        error => {
          console.log(error);
          this.menu = [];
        }
      );
    }
  },
  mounted() {
    this.getMenu();
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });
  }
};
</script>
