var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"kt_aside",staticClass:"aside aside-left d-flex flex-column",staticStyle:{"width":"120px"},attrs:{"id":"kt_aside"}},[_c('KTBrand'),_c('div',{staticClass:"aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7",staticStyle:{"width":"120px"}},[_c('ul',{staticClass:"nav flex-column"},_vm._l((_vm.menu),function(menu,index){return _c('router-link',{key:`menu-${index}-${menu.title}`,attrs:{"to":menu.target},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(menu.title),expression:"menu.title",modifiers:{"hover":true,"right":true}}],staticClass:"nav-item mb-14"},[_c('a',{staticClass:"nav-link btn btn-icon btn-icon-white btn-lg",attrs:{"href":href},on:{"click":navigate}},[(menu.routes.includes(_vm.$route.name))?_c('span',[_c('inline-svg',{attrs:{"src":menu.icons.active,"width":"112"}})],1):_c('span',[_c('inline-svg',{attrs:{"src":menu.icons.default,"width":"112"}})],1)])])]}}],null,true)})}),1)]),_c('div',{staticClass:"aside-footer d-flex flex-column align-items-center flex-column-auto py-8"},[_c('div',{staticClass:"d-flex justify-content-center mb-1 mt-1"},[_c('li',{staticClass:"menu-item",class:[
          _vm.isActive && 'menu-item-active',
          _vm.isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.href},on:{"click":function($event){return _vm.$bvModal.show('bv-modal-example')}}})])]),_c('div',{staticClass:"d-flex justify-content-center mb-5 mt-5"},[_c('li',{staticClass:"menu-item",class:[
          _vm.isActive && 'menu-item-active',
          _vm.isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.href},on:{"click":function($event){$event.preventDefault();return _vm.logOut.apply(null, arguments)}}},[_vm._m(0),_vm._m(1)])])])]),_c('b-modal',{attrs:{"id":"bv-modal-example","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("URL Aplikasi")]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('div',[_c('b-form-group',[_c('b-form-radio',{attrs:{"stacked":"","checked":"","aria-describedby":_vm.ariaDescribedby,"name":"some-radios","value":"https://zipato.sisnet.my.id/zipato-web/app2login"},model:{value:(_vm.urlSelected),callback:function ($$v) {_vm.urlSelected=$$v},expression:"urlSelected"}},[_vm._v("Aplikasi Visit")]),_c('b-form-radio',{attrs:{"stacked":"","aria-describedby":_vm.ariaDescribedby,"name":"some-radios","value":"https://zipato.sisnet.my.id/zipato-web/app2login"},model:{value:(_vm.urlSelected),callback:function ($$v) {_vm.urlSelected=$$v},expression:"urlSelected"}},[_vm._v("Aplikasi Visitor")])],1)],1)]),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"danger","block":""},on:{"click":_vm.GoToUrl}},[_vm._v("Go To Link")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-bottom":"10px"}},[_c('img',{staticClass:"logo-default max-h-20px",attrs:{"alt":"Visit","src":"media/icon/svg/vector_signout.svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"menu-text",staticStyle:{"color":"#d7dc09","bottom":"120px"}},[_vm._v("Sign Out")])])
}]

export { render, staticRenderFns }