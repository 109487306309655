import ApiService from "./api.service";
class WebService {
  //role
  getRoleAll() {
    return ApiService.get("/roles");
  }
  getRoleByID(id) {
    return ApiService.get(`/roles/${id}`);
  }
  addRole(data) {
    return ApiService.post("/roles", data);
  }
  editRole(data, id) {
    return ApiService.put(`/roles/${id}`, data);
  }
  deleteRole(id) {
    return ApiService.delete(`/roles/${id}`);
  }
  //users
  getUserAll() {
    return ApiService.get("/users");
  }
  addUser(data) {
    return ApiService.post("/users", data);
  }
  addUserSso(data) {
    return ApiService.post("/keycloak/create-user-with-password", data);
  }
  editUser(data, id) {
    return ApiService.put(`/users/${id}`, data);
  }
  deleteUser(id) {
    return ApiService.delete(`/users/${id}`);
  }
  //end users
  addDashboardItem(data) {
    return ApiService.post("/users/add-dashboard-items", data);
  }
  getUserLogged() {
    return ApiService.get("/auth/user");
  }
  // Profil
  getProfilUser() {
    return ApiService.get("/auth/user");
  }
  editProfile(data) {
    return ApiService.post("/users/update-profile", data);
  }
  editPassword(data) {
    return ApiService.post("/auth/password", data);
  }
  //permission
  getpermissionsAll() {
    return ApiService.get("/permissions");
  }
  addPermission(data) {
    return ApiService.post("/permissions", data);
  }
  editPermission(data, id) {
    return ApiService.put(`/permissions/${id}`, data);
  }
  deletePermission(id) {
    return ApiService.delete(`/permissions/${id}`);
  }
  //dashboard
  getdashboardAll() {
    return ApiService.get("/ref/dashboard-item");
  }
  addDashboard(data) {
    return ApiService.post("/ref/dashboard-item", data);
  }
  editDashboard(data, id) {
    return ApiService.put(`/ref/dashboard-item/${id}`, data);
  }
  deleteDashboard(id) {
    return ApiService.delete(`/ref/dashboard-item/${id}`);
  }
  getProvAll() {
    return ApiService.get("/ref/provinsi");
  }
  getrefProv() {
    return ApiService.get("/ref/provinsi?take=50");
  }
  addProv(data) {
    return ApiService.post("/ref/provinsi", data);
  }
  editProv(data, id) {
    return ApiService.put(`/ref/provinsi/${id}`, data);
  }
  deleteProv(id) {
    return ApiService.delete(`/ref/provinsi/${id}`);
  }
  //city
  getCityAll() {
    return ApiService.get("/ref/kabkota");
  }
  addCity(data) {
    return ApiService.post("/ref/kabkota", data);
  }
  editCity(data, id) {
    return ApiService.put(`/ref/kabkota/${id}`, data);
  }
  deleteCity(id) {
    return ApiService.delete(`/ref/kabkota/${id}`);
  }
  //logistik
  getAlllogistik(data) {
    return ApiService.get("/logistik/logistik" + data);
  }
  getAlllogistikDashboard() {
    return ApiService.get("/logistik/logistik");
  }
  getallLogistikWarehouse() {
    return ApiService.get("/logistik/warehouse");
  }
  addLogistict(data) {
    return ApiService.post("/logistik/logistik", data);
  }
  addLogistik(data) {
    return ApiService.post("/logistik/warehouse", data);
  }
  editLogistik(data, id) {
    return ApiService.put(`/logistik/warehouse/${id}`, data);
  }
  deleteLogistik(id) {
    return ApiService.delete(`/logistik/warehouse/${id}`);
  }
  deleteLogistik2(id) {
    return ApiService.delete(`/logistik/logistik/${id}`);
  }
  editLogistik2(data, id) {
    return ApiService.put(`/logistik/logistik/${id}`, data);
  }
  //visit
  getAllVisit(data) {
    return ApiService.post(`/sisnet/maintenance-list`, data);
  }
  //site data
  getallSiteData(data) {
    return ApiService.post(`/sisnet/site-data`, data);
  }
  //new ticket
  getallNewTicket(data) {
    return ApiService.post(`/sisnet/ticket`, data);
  }
  //maintenance
  getAlldataMaintenance() {
    return ApiService.get(`/ticket-maintenance`);
  }
  getAlldataMaintenanceList() {
    return ApiService.get(
      `/ticket-maintenance?sort=%5B%7B%22selector%22%3A%20%22created_at%22%2C%20%22desc%22%3A%20true%7D%5D&Status=new&take=10000`
    );
  }
  getAlldataMaintenanceListClose() {
    return ApiService.get(
      `/ticket-maintenance?sort=%5B%7B%22selector%22%3A%20%22created_at%22%2C%20%22desc%22%3A%20true%7D%5D&Status=close&take=10000`
    );
  }
  getallHistory() {
    return ApiService.get(
      `/ticket-closed-history?sort=%5B%7B%22selector%22%3A%20%22created_at%22%2C%20%22desc%22%3A%20true%7D%5D&Status=close&take=10000`
    );
  }
  getallHistorySupport() {
    return ApiService.get(
      `/ticket-closed-history?AsalTiket=ticket_support&sort=%5B%7B%22selector%22%3A%20%22created_at%22%2C%20%22desc%22%3A%20true%7D%5D&Status=close&take=10000`
    );
  }
  addMaintenance(data) {
    return ApiService.post("/ticket-maintenance", data);
  }
  editMaintenance(data, id) {
    return ApiService.put(`/ticket-maintenance/${id}`, data);
  }
  deleteMaintenance(id) {
    return ApiService.delete(`/ticket-maintenance/${id}`);
  }
  exportHistory(data) {
    return ApiService.post(
      `/ticket-closed-history/export?StartClosedAt=${data.start_date}&EndClosedAt=${data.end_date}&AsalTiket=${data.asal}`,
      {},
      {
        responseType: "blob"
      }
    );
  }
  exportNonClose(data) {
    return ApiService.post(
      `/ticket-support/export-non-closed?StartCreatedAt=${data.start_date}&EndCreatedAt=${data.end_date}`,
      {},
      {
        responseType: "blob"
      }
    );
  }
  //pending tiket
  getAllTiketPending() {
    return ApiService.get(`/ticket-process?take=50`);
  }
  getAllPendingByProblem(problem) {
    return ApiService.get(`/ticket-process?Problem=${problem}`);
  }
  getAllTiketPendingByCustomer(val) {
    return ApiService.get("/ticket-process?Customer=" + val);
  }
  addTiketPending(data) {
    return ApiService.post(`/ticket-process`, data);
  }
  editTiketPending(id, data) {
    return ApiService.put(`/ticket-process/${id}`, data);
  }
  editTiketPendingNew(id) {
    return ApiService.post(`/ticket-new/convert-to-process/${id}`);
  }
  editTiketprocessTiket(id, data) {
    return ApiService.put(`/ticket-support/${id}`, data);
  }
  deleteticketSupport(id) {
    return ApiService.delete(`/ticket-support/${id}`);
  }
  addticketSupport(data) {
    return ApiService.post(`/ticket-support`, data);
  }
  //get new tiket
  getnewTicket(data, skip) {
    return ApiService.get(
      `/ticket-process?Status=new&sort=${data}&skip=${skip}&take=10`
    );
  }
  getnewTicketProcess(data, skip) {
    return ApiService.get(
      `/ticket-new?Status=new&sort=${data}&skip=${skip}&take=10`
    );
  }
  getPendingTicket(data) {
    return ApiService.get(
      `/ticket-process?Status=pending&sort=${data}&take=10`
    );
  }
  getPendingTicketSearch(keyword) {
    return ApiService.get(
      `/ticket-process?SiteId=${keyword}&Status=pending&take=10`
    );
  }
  //history
  gethistoryPendingTiket(site_id) {
    let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
    return ApiService.get(
      `/audit-trail?entity=ticket_process&action=update&old_data=${site_id}&take=5&sort=${encodeURI(
        databody
      )}`
    );
  }
  gethistoryPending(id) {
    return ApiService.get(`/ticket-process-items/${id}`);
  }
  gethistorytiketSupportTiket(site_id) {
    let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
    return ApiService.get(
      `/audit-trail?entity=ticket_support&action=update&old_data=${site_id}&take=5&sort=${encodeURI(
        databody
      )}`
    );
  }
  getAuditTiket() {
    let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
    return ApiService.get(
      `/audit-trail?entity=ticket_&take=2000&new_data=close&sort=${encodeURI(
        databody
      )}`
    );
  }
  gethistoryMaintenance(site_id) {
    return ApiService.get(
      `/audit-trail?entity=ticket_maintenance&action=update&old_data=${site_id}`
    );
  }
  gethistoryCloseTiket() {
    return ApiService.get(`/ticket-process?Status=close&take=1000`);
  }
  // getSupportTiket(short) {
  //   return ApiService.get(`/ticket-process-summary?take=100&sort=site_id%20${short}`);
  // }
  getTicketprosesByid(id) {
    let databody = JSON.stringify([{ selector: "updated_at", desc: true }]);
    return ApiService.get(
      `/ticket-process?SiteId=${id}&take=1000&sort=${encodeURI(databody)}`
    );
  }
  gettiketnonclosed(id) {
    let databody = JSON.stringify([{ selector: "updated_at", desc: true }]);
    return ApiService.get(
      `/ticket-support-non-closed?SiteId=${id}&take=1000&sort=${encodeURI(
        databody
      )}`
    );
  }
  getsupportitems(id) {
    return ApiService.get(`/ticket-support-items/${id}`);
  }
  getSupportticket() {
    return ApiService.get(`/ticket-support-summary?take=1000`);
  }
  getshorttiketSupport(fill) {
    return ApiService.get(
      `/ticket-support-summary?take=1000&sort=number_of_tickets%20${fill}`
    );
  }
  getDetailSla() {
    return ApiService.get(`/ticket-support`);
  }
  getsupportbyid(id) {
    return ApiService.get(`/ticket-support/${id}`);
  }

  getDetailSlaBycustomer(name) {
    return ApiService.get(`/ticket-support?Customer=${name}`);
  }
  //Category
  getCategoryAll() {
    return ApiService.get("/ref/ticket-category?take=50");
  }
  getSubCategory() {
    return ApiService.get("/ref/ticket-subcategory?take=50");
  }
  getSubCategoryAll() {
    let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
    return ApiService.get(
      `/ref/ticket-subcategory?take=1000&sort=${encodeURI(databody)}`
    );
  }
  getSubCategoryById(id) {
    return ApiService.get(`/ref/ticket-subcategory?category_id=${id}&take=50`);
  }
  addCategory(data) {
    return ApiService.post("/ref/ticket-category", data);
  }
  editCategory(data, id) {
    return ApiService.put(`/ref/ticket-category/${id}`, data);
  }
  deleteCategory(id) {
    return ApiService.delete(`/ref/ticket-category/${id}`);
  }
  addsubCategory(data) {
    return ApiService.post("/ref/ticket-subcategory", data);
  }
  editsubCategory(data, id) {
    return ApiService.put(`/ref/ticket-subcategory/${id}`, data);
  }
  deletesubCategory(id) {
    return ApiService.delete(`/ref/ticket-subcategory/${id}`);
  }
  getAllcategoryRef() {
    return ApiService.get("/ref/ticket-category?take=1000");
  }
  //chart tiketing
  getChartTiketing() {
    return ApiService.get("/ticket-new-chart");
  }
  getchartBygroupField(val) {
    return ApiService.get("/ticket-process/chart-group/" + val);
  }
  getchartBygroupFieldnew(val) {
    return ApiService.get("/ticket-new/chart-group/" + val);
  }
  getchartBygroupFieldByCustomer(val) {
    return ApiService.get("/ticket-process/chart-group/status?Customer=" + val);
  }
  getProblemByOwner(val, customer) {
    return ApiService.get(
      "/ticket-process/chart-group/problem?Problem=" +
        val +
        "&Customer=" +
        customer
    );
  }
  getProblemChartByOwner(val) {
    return ApiService.get(
      "/ticket-process/chart-group/problem?Customer=" + val
    );
  }
  getProblem(val) {
    return ApiService.get("/ticket-process/chart-group/problem?Problem=" + val);
  }
  getsitelocationbyName(val) {
    return ApiService.get(
      "/ticket-process/chart-group/site_location?Customer=" + val
    );
  }
  getsitelocationbyNameNew(val) {
    return ApiService.get(
      "/ticket-new/chart-group/site_location?Customer=" + val
    );
  }
  getOnlineDeviceByOwner(val) {
    return ApiService.post("/sisnet/online-devices?OwnerName=" + val);
  }
  getchartSiteOnline() {
    return ApiService.post("/sisnet/online-devices");
  }
  getChartPrior() {
    return ApiService.get("/sisnet/site-data/priority-chart-group");
  }
  getChartPriorBycustomerId(val) {
    return ApiService.get(
      "/sisnet/site-data/priority-chart-group?customer_id=" + val
    );
  }
  //email
  SendEmail(data) {
    return ApiService.post("/sisnet/send-email", data);
  }
  //customer master
  getAllCustomer(param) {
    return ApiService.get("/customer" + param);
  }
  getRefCustomerById() {
    return ApiService.get("/customer?take=50");
  }
  getRefCustomer() {
    return ApiService.get("/customer?take=50");
  }
  addCustomer(data) {
    return ApiService.post("/customer", data);
  }
  deleteCustomer(id) {
    return ApiService.delete(`/customer/${id}`);
  }
  editCustomer(data, id) {
    return ApiService.put(`/customer/${id}`, data);
  }
  //device
  getAllDevicewithparam(param) {
    return ApiService.get("/device" + param);
  }
  getAllDevice() {
    return ApiService.get("/device");
  }
  addDevice(data) {
    return ApiService.post("/device", data);
  }
  deleteDevice(id) {
    return ApiService.delete(`/device/${id}`);
  }
  editDevice(data, id) {
    return ApiService.put(`/device/${id}`, data);
  }
  getlocalsiteAll(data) {
    return ApiService.get(`/local-site-data?take=1200&sort=${data}`);
  }
  getlocalsiteref() {
    return ApiService.get(`/local-site-data?take=120`);
  }
  getAllchartLocation() {
    return ApiService.get(`/ticket-new/chart-group/site_location`);
  }
  getAllchartLocationByCustomer(cust) {
    return ApiService.get(
      `/ticket-new/chart-group/site_location?Customer=${cust}`
    );
  }
  getlocalsiterefBysiteID(data) {
    return ApiService.get(`/local-site-data?take=1&site_id=${data}`);
  }
  getlocalsiteById(id) {
    return ApiService.get(`/local-site-data?customer_id=${id}`);
  }
  addlocalsite(data) {
    return ApiService.post("/local-site-data", data);
  }
  getlocalsiteBySiteid(siteid){
    return ApiService.get(`/local-site-data/by-site-id/${siteid}`);
  }
  editLocalsite(data, id) {
    return ApiService.put(`/local-site-data/${id}`, data);
  }
  deletelocalsite(id) {
    return ApiService.delete(`/local-site-data/${id}`);
  }
  getAllmenu(param) {
    return ApiService.get("/menus" + param);
  }
  getMenuRef() {
    return ApiService.get("/menus");
  }
  addMenu(data) {
    return ApiService.post("/menus", data);
  }
  deleteMenu(id) {
    return ApiService.delete(`/menus/${id}`);
  }
  editMenu(data, id) {
    return ApiService.put(`/menus/${id}`, data);
  }
  //dashboard status
  getDashboardStatus() {
    return ApiService.post("/sisnet/dashboard");
  }
  getAlldataOnlineDevice() {
    return ApiService.post("/sisnet/online-devices");
  }
  getAlldataOnlineDeviceByOwner(val) {
    return ApiService.post("/sisnet/online-devices?OwnerName=" + val);
  }
  getPendingPart(body) {
    return ApiService.post(
      "/sisnet/maintenance-list?StatusAkhirPekerjaan=PENDING%20PART",
      body
    );
  }
  convertTotiketSupport(id) {
    return ApiService.post("/ticket-process/convert-to-support/" + id);
  }
  //api camera
  apiCamera(data) {
    return ApiService.post("/sisnet/camera-snapshot", data, {
      timeout: 1000 * 10
    });
  }
  getSiteId(data) {
    return ApiService.get(`/local-site-data?site_id=${data}`);
  }
  putNewTicket(id, data) {
    return ApiService.put(`/ticket-new/${id}`, data);
  }
  resetNewTicket(id) {
    return ApiService.post(`/ticket-new/reset/${id}`);
  }
  putTicketSupport(id, data) {
    return ApiService.put(`/ticket-support/${id}`, data);
  }
  getListTicketSupport(id) {
    return ApiService.get(`/ticket-support/history/${id}?take=1000&skip=0`);
  }
}

export default new WebService();
