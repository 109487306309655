<template>
  <div class="topbar-item">
    <router-link to="/profil">
      <div
        class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        id="kt_quick_user_toggle"
      >
        <div class="d-flex flex-column text-right pr-3">
          <span
            class="text-muted font-weight-bold font-size-base d-none d-md-inline"
            >{{dataProfil.first_name}}</span
          >
          <span
            class="text-dark-75 font-weight-bolder font-size-base d-none d-md-inline"
            >{{dataProfil.email}}</span
          >
        </div>
        <span class="symbol symbol-35 symbol-light-primary">
          <span class="symbol-label font-size-h5 font-weight-bold">S</span>
        </span>
      </div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import WebService from '../../../../core/services/api'

export default {
  name: "KTQuickUser",
  data() {
    return {
      dataProfil: {},
    };
  },
  mounted() {
    // Init Quick User Panel
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    WebService.getUserLogged().then(
        response => {
          this.dataProfil = response.data,
              console.log(this.dataProfil);
        },
    );
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    currentUser() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>
